import React, { useEffect } from "react";
import LoginButton from "./LoginButton";
import CreateUserForm from "./CreateUserForm";
import LogoutButton from "./LogoutButton";
import LinkedUsers from "./LinkedUsers";
import Profile from "./Profile";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { beUrl } from "./config";

export default function MyApp() {
  const [listUsers, setListUsers] = React.useState([]);
  const [jiraTickets, setJiraTickets] = React.useState([]);
  const [userOrganizations, setUserOrganizations] = React.useState([]);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims()
        .then((claims) => callApi(claims.__raw))
        .then((userFromDb) => {
          setListUsers(
            userFromDb.users.map((user) => (
              <tr key={user.name + user.email}>
                <td>{user.name}</td>
                <td>{user.email}</td>
              </tr>
            ))
          );
          setUserOrganizations(
            userFromDb.organizations.map((org) => (
              <tr key={org.id}>
                <td>{org.id}</td>
                <td>{org.name}</td>
                <td>{org.displayName}</td>
              </tr>
            ))
          );
        })
        .catch((err) => {
          console.log(err);
        });

      getIdTokenClaims()
        .then((claims) => getJiraTickets(claims.__raw))
        .then((response) => {
          setJiraTickets(
            response.map((ticket) => (
              <tr key={ticket.key}>
                <td>{ticket.key}</td>
                <td>{ticket.fields.summary}</td>
                <td>{ticket.fields.assignee?.displayName}</td>
                <td>{ticket.fields.status?.name}</td>
              </tr>
            ))
          );
        });
    }
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <div>
      <div className="landing">
        <h1 className="heading-inapp-copy clipped">Talsec Auth0 Example</h1>

        <p>
          <LoginButton />
          <LogoutButton />
        </p>
      </div>
      <div className="content">
        <Profile />

        {isAuthenticated && (
          <div className="userList">
            <h3>All user organizations from Auth0 API</h3>
            <table>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>Name</td>
                  <td>Display Name</td>
                </tr>
                {userOrganizations}
              </tbody>
            </table>
          </div>
        )}

        {isAuthenticated && (
          <div className="userList">
            <h3>Users from Firestore DB</h3>
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>Email</td>
                </tr>
                {listUsers}
              </tbody>
            </table>
          </div>
        )}

        {isAuthenticated && (
          <div className="userList">
            <h3>Jira tickets</h3>
            <table>
              <tbody>
                <tr>
                  <td>Key</td>
                  <td>Title</td>
                  <td>Assignee</td>
                  <td>Status</td>
                </tr>
                {jiraTickets}
              </tbody>
            </table>
          </div>
        )}

        <CreateUserForm />
        <LinkedUsers />
      </div>
    </div>
  );
}

const callApi = async (token) => {
  let user;
  try {
    const userResponse = await axios.get(
      `${beUrl}/api/firebase`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(userResponse.data);
    user = userResponse.data;
  } catch (err) {
    console.log(err);
  }
  return user;
};

const getJiraTickets = async (token) => {
  let tickets = [];
  try {
    const response = await axios.get(
      `${beUrl}/api/jira`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    tickets = response.data;
  } catch (err) {
    console.log(err);
  }
  return tickets;
};
