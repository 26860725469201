import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  function onLoginClicked() {
    loginWithRedirect().then((result) => {
      console.log("Login result: ", result);
    });
  }

  return <button onClick={onLoginClicked}>Log In</button>;
};

export default LoginButton;
