import React, { useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { beUrl } from "./config";

const LinkedUsers = () => {
  const [listUsers, setListUsers] = React.useState([]);

  const { user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((currentAccessToken) => {
        const userId = user.sub;
        getUserProfile(userId, currentAccessToken).then((profile) => {
          console.log(profile);
          getIdTokenClaims().then((claims) => {
            getUserWithSameEmail(claims.__raw).then((users) => {
              if (!users) return;
              setListUsers(
                users.map((item) => (
                  <tr key={item.user_id}>
                    <td>
                      <strong>{item.user_id}</strong>
                    </td>
                    <td>{item.email}</td>
                    <td>{item.identities.map((i) => i.provider).join(", ")}</td>
                    <td>
                      <button
                        onClick={() => {
                          // if (!claims.email_verified) {
                          //   // don't offer linking for unverified emails.
                          //   // TODO: check both emails
                          //   alert(
                          //     "Please verify your email before linking users."
                          //   );
                          //   return;
                          // }
      
                          linkAccount(claims.__raw, user, item).then(() => {
                            loginWithRedirect();
                            // getAccessTokenSilently();
                          });
                        }}
                      >
                        Link
                      </button>
                    </td>
                  </tr>
                ))
              );
            });
          });

          // authenticateUser();
          // linkUsers(userId);
        });
      });
    }
  }, [getIdTokenClaims, isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  return (
    <div>
      {isAuthenticated && (
        <div className="userList">
          <h3>Users with the same email</h3>
          <table>
            <tbody>
              <tr>
                <td>User Id</td>
                <td>Email</td>
                <td>Provider</td>
                <td>Action</td>
              </tr>
              {listUsers}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const getUserWithSameEmail = async (token) => {
  let users;
  try {
    const userResponse = await axios.get(
      `${beUrl}/api/auth0/user-accounts`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(userResponse.data);
    users = userResponse.data;
  } catch (err) {
    console.log(err);
  }
  return users;
};

const linkAccount = async (token, actualUser, linkedUser) => {
  console.log("Linking accounts");
  console.log(actualUser, linkedUser);
  // TODO: udelat lepsi
  const primaryAccountId = actualUser.sub.startsWith("auth0|")
    ? actualUser.sub
    : linkedUser.user_id;
  const secondaryAccountId = actualUser.sub.startsWith("auth0|")
    ? linkedUser.user_id
    : actualUser.sub;
  const secondaryAccountProvider = secondaryAccountId.split("|")[0];
  try {
    const response = await axios.post(
      `${beUrl}/api/auth0/link-accounts`,
      {
        primaryAccountId,
        secondaryAccountId,
        secondaryAccountProvider,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
  } catch (err) {
    console.log(err);
  }
};

const getUserProfile = async (userId, token) => {
  const response = await fetch(
    `https://talsec-test.eu.auth0.com/api/v2/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return await response.json();
};

export default LinkedUsers;
