import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [roles, setRoles] = React.useState([]);
  const [organization, setOrganization] = React.useState("");

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      if (isAuthenticated) {
        console.log("Claims: ", claims);
        setRoles(claims["custom_claim:roles"]);
        setOrganization(claims["org_id"]);
      }
    });
  }, [getIdTokenClaims, isAuthenticated]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div className="profile">
        <img src={user.picture} alt={user.name} />
        <div className="profile-content">
          <h2>Name: {user.name}</h2>
          <p>Email: {user.email}</p>
          <p>Roles: {roles?.join(", ")}</p>
          <p>Logged Organization: {organization}</p>
        </div>
      </div>
    )
  );
};

export default Profile;
