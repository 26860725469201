import React, { useEffect } from "react";
import axios from "axios";
import { beUrl } from "./config";

const CreateUserForm = () => {
  const [userLink, setUserLink] = React.useState("");

  function createUser() {
    const emailInput = document.getElementById("userEmail");
    const userEmail = emailInput.value;
    console.log(`You searched for '${userEmail}'`);
    createUserApi(userEmail)
      .then((link) => {
        setUserLink(link);
      })
      .catch((err) => {
        console.log(err);
        alert("Error creating user");
      });
  }
  return (
    <div className="flex-column">
        <input id="userEmail" />
        <button onClick={createUser}>Create User</button>
      {userLink && <a href={userLink}>{userLink}</a>}
    </div>
  );
};

const createUserApi = async (email) => {
  console.log("Calling create user API");
  const userResponse = await axios.post(
    `${beUrl}/api/create-user`,
    {
      email: email,
    }
  );
  console.log(userResponse.data);
  return userResponse.data;
};

export default CreateUserForm;
