import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  function onLogoutClicked() {
    logout({ logoutParams: { returnTo: window.location.origin } }).then(() => {
      console.log("Logged out");
      alert("Logged out");
    });
  }

  return (
    <button onClick={onLogoutClicked}>
      Log Out
    </button>
  );
};

export default LogoutButton;
