import React from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";

const urlParams = new URLSearchParams(window.location.search);
const email = urlParams.get("email");
const success = urlParams.get("success");
const message = urlParams.get("message");
const errorDescription = urlParams.get("error_description");
const error = urlParams.get("error");

if (success === "true") {
  alert(`User ${email} created successfully: ${message}`);
} else if (success === "false") {
  alert(`Error creating user ${email}: ${message}`);
}

if (error) {
  alert(`${errorDescription}`);
}

const root = createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="talsec-test.eu.auth0.com"
    clientId="vqFoXEwrmDlJ7amIYKgZ45eZbfp4HBpL"
    authorizationParams={{
      redirect_uri: window.location.origin, // Must be set
      audience: "https://talsec-test.eu.auth0.com/api/v2/",
      login_hint: email || undefined,
      scope: "openid email profile read:current_user update:current_user_identities",
      linking_social: true, // Custom parameter, access it in Acction: event.request.query['linking_social']
    }}
  >
    <App />
  </Auth0Provider>
);
